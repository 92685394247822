/* You can add global styles to this file, and also import other style files */

html, body { 
  height: 100%; 
  background-color: #f0f0f0;
}
body { 
    margin: 0; 
    font-family: Roboto, "Helvetica Neue", sans-serif; 
    .mdc-button.mat-success,
    .mat-mdc-stroked-button.mat-success {
      color: #155724;
    }
    .mat-mdc-icon-button.mat-success {
      color: #155724;
    }
}

@media (max-width: 600px) {

  .login-wrapper {
    overflow-x: hidden;
    overflow: hidden;
    display: block;
  }

  h1 {
      font-size: 20px;
  }

  p {
      font-size: 14px;
  }

  button {
      width: 100%;
      padding: 15px;
  }
}
@import 'node_modules/primeflex/primeflex.css';